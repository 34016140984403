import { Link } from "react-router-dom"
import AdminHeader from "../AdminHeader"
import isAdmin from "../../AdminFunctions/isAdmin"
import AdminLogin from "../AdminLogin"
import { useEffect, useState } from "react"

function AdminHome() {

  const data = [
    {
      title: 'Criar novo usuário',
      to: 'create'
    },
    {
      title: 'Verificar contas',
      to: 'verified'
    },
    {
      title: '❖ Conta Premium',
      to: 'make/premium'
    },
    {
      title: '✯ Conta Parceira',
      to: 'partner'
    },
    {
      title: 'Gerar novo código estendido',
      to: 'generate/extended/code'
    }
  ]

  const [refresh, setRefresh] = useState<boolean>(false);
  useEffect(() => { }, [refresh])

  return (
    <div className="center column">
      <AdminHeader />
      {
        (isAdmin() || refresh)
          ? <ul>
            {
              data.map((it, idx) =>
                <AdminHomeLI
                  title={it.title}
                  to={it.to} idx={idx}
                  key={idx}
                />
              )
            }
          </ul>
          : <AdminLogin refresh={setRefresh}/>
      }
    </div>
  )
}

const AdminHomeLI = ({ title, idx, to }: { title: string, idx: number, to: string }) => (
  <Link to={'/admin/' + to}>
    <li style={{
      border: '1px solid #c4c4c4',
      backgroundColor: 'white',
      borderRadius: '8px',
      marginBottom: '20px',
      width: '280px',
      height: '50px',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    }}
    >
      <strong style={{ padding: '0 20px' }}>{idx}</strong>
      {title}
    </li>
  </Link>
)

export default AdminHome