
import { Link } from 'react-router-dom'
import AdminCounter from '../AdminCounter'

function AdminHeader() {
    return (
        <header
            className='center'
            style={{
                width: '100%',
                height: '70px',
                backgroundColor: '#222D36',
                color: 'white',
                display: 'flex',
                justifyContent: 'space-between',
                boxShadow: '4px 4px 8px #00000030',
                marginBottom: '50px'
            }}
        >
            <Link to='/admin'>
                <img
                    src={require('../../../assets/logo.png')}
                    style={{ width: '50px', height: '50px', padding: '0 30px' }}
                    alt='logo'
                />
            </Link>
            <div style={{ margin: '0 30px' }}>
                <AdminCounter full={false} />
            </div>
        </header>
    )
}

export default AdminHeader