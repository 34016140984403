
import { useState } from 'react';

import AdminHeader from '../AdminHeader';
import '../adminFormStyles.css';
import api from '../../../config/connection/axios';
import isAdmin from '../../AdminFunctions/isAdmin';
import P404 from '../../../pages/P404';


function AdminExtendedCode() {
    const [spinner, setSpinner] = useState<boolean>(false);

    const [email, setEmail] = useState<string>('');
    const [admin, setAdmin] = useState<string>('');
    const [screen, setScreen] = useState<1 | 2>(1);
    const [code, setCode] = useState<any>({});

    const handleAdminSubmit = async (e: Event | any) => {
        e.preventDefault();

        if (
            !email ||
            !admin
        ) return alert('Os campos estão vazios!');

        const u = JSON.parse(window.sessionStorage.getItem('admin-serss-user') || '{}');
        if (u.token) {
            setSpinner(true);

            await api.post(
                '/admin/generate/new/user/code',
                {
                    email: email.trim(),
                    adminPassword: admin,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${u.token}`
                    }
                }
            )
                .then((code) => { setSpinner(false); setScreen(2); setCode(code) })
                .catch(error => { setSpinner(false); alert(error) });
        } else alert('Faça login no admin')
    }

    return (
        <div id='adminForm'>
            {
                isAdmin()
                    ? (<>
                        <AdminHeader />
                        <main>
                            {
                                screen === 1
                                    ? <form>
                                        <label>
                                            E-mail
                                            <input
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </label>
                                        <label style={{ color: 'tomato', marginTop: '30px' }}>
                                            Senha do Administrador
                                            <input
                                                style={{ border: '1px solid tomato' }}
                                                value={admin}
                                                onChange={(e) => setAdmin(e.target.value)}
                                                type='password'
                                            />
                                        </label>

                                        <button
                                            disabled={spinner}
                                            style={{ backgroundColor: 'tomato', marginTop: '8px', height: '50px', padding: '0 20px' }}
                                            onClick={(e) => handleAdminSubmit(e)}
                                        >
                                            {spinner ? 'Carregando...' : 'Gerar novo código'}
                                        </button>
                                    </form>
                                    :<div><code>{JSON.stringify(code)}</code></div>
                            }
                        </main>
                    </>)
                    : (<P404 />)
            }
        </div>
    )
}

export default AdminExtendedCode;