import { useState } from "react";
import isAdmin from "../../AdminFunctions/isAdmin"
import AdminHeader from "../AdminHeader"
import P404 from "../../../pages/P404";
import '../adminFormStyles.css';
import api from "../../../config/connection/axios";

function AdminMakePremium() {

    const [admin, setAdmin] = useState<string>('');
    const [spinner, setSpinner] = useState<boolean>(false);
    const [wid, setWid] = useState<string>('');
    const [months, setMonths] = useState<number | string>(1);

    const handleAdminPremiumSubmit = async (e: Event) => {
        e.preventDefault();

        if (
            !wid ||
            !months
        ) return alert('Os campos estão vazios!');

        const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}');
        if (u.token) {
            setSpinner(true);

            await api.post(
                '/works/make/premium',
                {
                    wid: wid.trim(),
                    months,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${u.token}`
                    }
                }
            )
                .then(() => { setSpinner(false); alert('Sucesso!') })
                .catch(error => { setSpinner(false); alert(error) });
        } else alert('Faça login no admin')
    }

    return (
        <div id='adminForm'>
            {
                isAdmin()
                    ? (<>
                        <AdminHeader />
                        <main>
                            <form>
                                <label>
                                    Identificador público
                                    <input
                                        value={wid}
                                        onChange={(e) => setWid(e.target.value)}
                                    />
                                </label>
                                <br />
                                <label>
                                    Meses
                                    <select
                                        name="months"
                                        id="admPremiumMonths"
                                        value={months}
                                        onChange={e => setMonths(e.target.value)}
                                    >
                                        <option value={1}>1 mês</option>
                                        <option value={3}>3 meses</option>
                                        <option value={12}>12 meses</option>
                                    </select>
                                </label>
                                <label style={{ color: 'tomato', marginTop: '30px' }}>
                                    Senha do Administrador
                                    <input
                                        style={{ border: '1px solid tomato' }}
                                        value={admin}
                                        onChange={(e) => setAdmin(e.target.value)}
                                        type='password'
                                    />
                                </label>

                                <button
                                    disabled={spinner}
                                    style={{ backgroundColor: 'tomato', marginTop: '8px', height: '50px', padding: '0 20px' }}
                                    onClick={(e: any) => handleAdminPremiumSubmit(e)}
                                >
                                    {spinner ? 'Carregando...' : 'Premiar anúncio'}
                                </button>
                            </form>
                        </main>
                    </>)
                    : (<P404 />)
            }
        </div>
    )
}

export default AdminMakePremium