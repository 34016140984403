
import { useState } from 'react';

import AdminHeader from '../AdminHeader';
import '../adminFormStyles.css';
import api from '../../../config/connection/axios';
import isAdmin from '../../AdminFunctions/isAdmin';
import P404 from '../../../pages/P404';


function AdminCreateUser() {
    const [spinner, setSpinner] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [prof, setProf] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [admin, setAdmin] = useState<string>('');

    function generateRadomPassword() {
        const length = 8;
        var result = '';
        var characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return setPassword(result.toUpperCase().trim());
    }



    const handleAdminSubmit = async (e: Event | any) => {
        e.preventDefault();

        if (
            !name ||
            !email ||
            !password ||
            !admin ||
            typeof email !== 'string' ||
            typeof password !== 'string'
        ) return alert('Os campos estão vazios!');

        const u = JSON.parse(window.sessionStorage.getItem('admin-serss-user') || '{}');
        if (u.token) {
            setSpinner(true);

            await api.post(
                '/admin/users/create',
                {
                    email: email.trim(),
                    name,
                    profession: prof,
                    randomPassword: password.toUpperCase().trim(),
                    adminPassword: admin,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${u.token}`
                    }
                }
            )
                .then(() => { setSpinner(false); alert('Sucesso!') })
                .catch(error => { setSpinner(false); alert(error) });
        } else alert('Faça login no admin')
    }

    return (
        <div id='adminForm'>
            {
                isAdmin()
                    ? (<>
                    <AdminHeader />
                        <main>
                            <form>
                                <label>
                                    Nome
                                    <input
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </label>
                                <label>
                                    Profissão
                                    <input
                                        value={prof}
                                        onChange={(e) => setProf(e.target.value)}
                                    />
                                </label>
                                <label>
                                    E-mail
                                    <input
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </label>
                                <label>
                                    Senha
                                    <input
                                        value={password}
                                        disabled
                                    />
                                    <span
                                        className='center'
                                        onClick={generateRadomPassword}
                                        style={{ backgroundColor: '#222d36', color: 'white', width: '100%', padding: '10px 0',  marginTop: '10px' }}
                                    >
                                        Gerar senha
                                    </span>
                                    <span
                                        className='center'
                                        onClick={() => { navigator.clipboard.writeText(password) }}
                                        style={{ backgroundColor: '#c4c4c4', color: '#222d36', width: '100%', padding: '10px 0', marginTop: '10px' }}
                                    >
                                        Copiar senha
                                    </span>
                                </label>
                                <label style={{ color: 'tomato', marginTop: '30px' }}>
                                    Senha do Administrador
                                    <input
                                        style={{ border: '1px solid tomato' }}
                                        value={admin}
                                        onChange={(e) => setAdmin(e.target.value)}
                                        type='password'
                                    />
                                </label>

                                <button
                                    disabled={spinner}
                                    style={{ backgroundColor: 'tomato', marginTop: '8px', height: '50px', padding: '0 20px' }}
                                    onClick={(e) => handleAdminSubmit(e)}
                                >
                                    {spinner ? 'Carregando...' : 'Criar nova conta'}
                                </button>
                            </form>
                        </main>
                    </>)
                    : (<P404 />)
            }
        </div>
    )
}

export default AdminCreateUser;