import { useEffect, useState } from "react";
import AdminHeader from "../AdminHeader";
import '../adminFormStyles.css';

import { auth, storage } from "../../../config/firebase";
import api from "../../../config/connection/axios";
import isAdmin from "../../AdminFunctions/isAdmin";
import FullScreenImage from "../../../components/FullScreenImage";

function AdminPartner() {

    const [files, setFiles] = useState<Array<string>>([]);
    const [fullVisible, setFullVisible] = useState<boolean>(false);
    const [srcImages, setSrcImages] = useState<string>('');
    const [selected, setSelected] = useState<{ uid: string, url: string }>({ uid: '', url: '' });
    const [spinner, setSpinner] = useState<boolean>(false);

    useEffect(() => {
        const adminU = isAdmin()

        if (
            adminU &&
            !window.sessionStorage.getItem('admin-serss-custom-token')
        ) {
            api.get(
                '/admin/verified/custom/token',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${adminU.token}`
                    }
                }
            )
                .then(r => {
                    const result = r.data;
                    result.customToken && auth.signInWithCustomToken(result.customToken)
                        .then((userCredential) => {
                            var user = userCredential.user;
                            window.sessionStorage.setItem('admin-serss-custom-token', result.customToken)
                        })
                        .catch((error) => {
                            console.error(error)
                        });
                })
                .catch((error) => console.error(error))
        }
    }, [])

    const fetchImages = async () => {
        let result = await storage.ref().child('partner').listAll();
        let urlPromises = result.items.map((imageRef: any) => {
            return imageRef.getDownloadURL();
        }
        );

        return Promise.all(urlPromises);
    };

    const loadImages = async () => {
        const urls: any = await fetchImages();
        setFiles(urls);
    };

    const handleFileSelectionAP = (img: string) => {
        const start = img.toString().indexOf('%2F') + 3;
        const end = img.toString().indexOf('%5B');
        const getUid = img.toString().slice(start, end)

        setSelected({
            uid: getUid,
            url: img.toString()
        });
        setSrcImages(img);
        setFullVisible(true);
    }

    const handleAPSubmit = async (e: Event | any) => {
        e.preventDefault();

        if (!selected.uid) return alert('Campos vazios');

        const u = JSON.parse(window.sessionStorage.getItem('admin-serss-user') || '{}');
        if (u.token) {
            setSpinner(true);

            await api.post(
                '/admin/partner/make',
                {
                    uid: selected.uid.toString().trim(),
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${u.token}`
                    }
                }
            )
                .then(() => {
                    setSelected({ uid: '', url: '' })
                    setSpinner(false);
                    alert('Sucesso!');
                    const index = files.indexOf(selected.url);
                    setFiles(files => files.splice(index, 1))
                })
                .catch(() => setSpinner(false));
        } else alert('Faça login no admin')
    }


    return (
        <div id="adminForm">
            <AdminHeader />
            {
                (fullVisible && srcImages.length) &&
                <FullScreenImage
                    setVisible={setFullVisible}
                    src={srcImages}
                />
            }
            <main style={{ display: 'flex' }}>
                <button onClick={loadImages} style={{ backgroundColor: '#c4c4c4' }}>Carregar imagens</button>
                <div className="center column">
                    <input value={selected.uid} disabled />
                    {
                        spinner 
                        ? <p>Loading...</p>
                        : <button
                        onClick={handleAPSubmit}
                        style={{ backgroundColor: 'yellowgreen' }}
                    >
                        Confirmar
                    </button>
                    }
                </div>
                <div style={{ height: '100%', width: '50%' }}>
                    <form>

                        <dl
                            style={{
                                marginTop: '40px',
                                backgroundColor: 'white',
                                borderRadius: '12',
                                padding: '10px 20px'
                            }}
                        >
                            <dt style={{ marginBottom: '20px', }}>Imagens:</dt>
                            {
                                files.map((img: any, idx: number) => (
                                    <dd
                                        onClick={() => handleFileSelectionAP(img)}
                                        key={idx}
                                        style={{
                                            maxWidth: '300px',
                                            maxHeight: '20px',
                                            overflow: 'hidden',
                                            marginBottom: '20px',
                                            backgroundColor: '#00ae7430',
                                            padding: '10px'
                                        }}
                                    >
                                        <p style={{ margin: 0 }} >{img}</p>
                                    </dd>
                                ))
                            }
                        </dl>
                    </form>

                </div>
            </main>
        </div>
    )
}


export default AdminPartner;