import { useEffect, useState } from "react";
import api from "../../../config/connection/axios";



function AdminCounter({ full = true }) {

    const [countdown, setCountdown] = useState<Array<string>>([]);
    useEffect(() => {
        const fetch = async () => {

            const adminU = JSON.parse(window.sessionStorage.getItem('admin-serss-user') || '{}');

            await api.get(
                '/admin/countdown',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${adminU.token}`
                    }
                }
                )
                .then((count) => setCountdown(count.data.toString().split('')))
        }
        fetch();
    }, [])

    return (
        <div className="center">
            <ul className="center" style={{ height: '100vh', display: 'flex', width: 'fit-content', fontSize: '18px', color: '#222d36', fontWeight: 'bold' }}>
                {countdown.map((it, idx) => (<CountLi full={full} idx={idx} key={idx}>{it}</CountLi>))}
            </ul>
        </div>
    )
}

const CountLi = ({ children, idx, full }: { children: string, idx: number, full: boolean }) => (
    <li style={{ backgroundColor: '#eee', height: '14px', width: '4px', boxShadow: full ? '-4px 4px 4px #dee6eb' : 'none', borderRadius: '4px', padding: '14px', border: '1px solid #c4c4c4', marginRight: idx === 1 ? '14px' : '4px', position: 'relative' }}>
        <strong className='center' style={{ zIndex: 3, position: 'absolute', height: '100%', width: '100%', bottom: 0, left: 0, }}>{children}</strong>
        <span style={{ zIndex: 0, backgroundColor: '#dee6eb80', bottom: 0, left: 0, height: '50%', width: '100%', borderTop: '1px solid #c4c4c4', position: 'absolute', borderRadius: '0 0 6px 6px' }}></span>
    </li>
)
export default AdminCounter