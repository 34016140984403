import { useEffect, useState } from "react";
import AdminHeader from "../AdminHeader";
import "../adminFormStyles.css";

import { auth, storage } from "../../../config/firebase";
import api from "../../../config/connection/axios";
import cpf from "../../../functions/cpf";
import FullScreenImage from "../../../components/FullScreenImage";
import isAdmin from "../../AdminFunctions/isAdmin";
import Spinner from "../../../components/Spinner";
import netlifyUrl from "../../../constants/netilifyUrl";

function AdminVerified() {
  const [uid, setUid] = useState<string>("");
  const [doc, setDoc] = useState<string>("");
  const [validCpf, setValidCpf] = useState<boolean>(false);
  //const [admin, setAdmin] = useState<string>("");
  const [spinner, setSpinner] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [fullVisible, setFullVisible] = useState<boolean>(false);
  //const [images, setImages] = useState<Array<string>>([]);
  const [srcImages, setSrcImages] = useState<string>("");
  const [refresh, setRefresh] = useState<boolean>(false);

  const [files, setFiles] = useState<Array<string>>([]);

  const handleDoc = (value: string) => {
    setValidCpf(cpf(value));
    setDoc(value);
  };

  const handleAVSubmit = async (e: Event | any, deny = false) => {
    e.preventDefault();

    if (!uid || !doc) return alert("Campos vazios");
    if (!validCpf) return alert("Cpf invalido");

    const u = JSON.parse(
      window.sessionStorage.getItem("admin-serss-user") || "{}"
    );
    if (u.token) {
      setSpinner(true);

      await api
        .post(
          "/admin/verified/make",
          {
            uid: uid.toString().trim(),
            document: doc.toString().trim(),
            deny,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${u.token}`,
            },
          }
        )
        .then(() => {
          setSpinner(false);
          setUid("");
          setDoc("");
          setRefresh(refresh ? false : true);
          alert("Sucesso!");
        })
        .catch((error) => {
          setSpinner(false);
          setUid("");
          setDoc("");
          alert(error);
        });
    } else alert("Faça login no admin");
  };

  useEffect(() => {
    const adminU = isAdmin();

    if (adminU && !window.sessionStorage.getItem("admin-serss-custom-token")) {
      api
        .get("/admin/verified/custom/token", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${adminU.token}`,
          },
        })
        .then((r) => {
          const result = r.data;
          result.customToken &&
            auth
              .signInWithCustomToken(result.customToken)
              .then((userCredential) => { /*.then((userCredential) => {*/
                //var user = userCredential.user;
                window.sessionStorage.setItem(
                  "admin-serss-custom-token",
                  result.customToken
                );
              })
              .catch((error) => {
                console.error(error);
              });
        })
        .catch((error) => console.error(error));
    }

    const fetchImages = async (user_uid: string) => {
      let result = await storage
        .ref()
        .child("verified/" + user_uid)
        .listAll();
      let urlPromises = result.items.map((imageRef: any) => {
        return imageRef.getDownloadURL();
      });

      return Promise.all(urlPromises);
    };

    const loadImages = async (user_uid: string) => {
      const urls: any = await fetchImages(user_uid);
      setFiles(urls);
    };

    const handleGetData = async () => {
      //e.preventDefault();
      await api
        .get("/admin/verified/list", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${adminU.token}`,
          },
        })
        .then((response) => {
          if (!response.data.count) {
            setUid("");
            setDoc("");
            setValidCpf(false);
            setCount(0);
            setFiles([]);
          } else {
            const cpfText =
              response.data.user.document.toString().length === 11
                ? response.data.user.document.toString()
                : "0" + response.data.user.document.toString();
            setUid(response.data.user.uid);
            setDoc(cpfText);
            setValidCpf(cpf(cpfText));
            setCount(response.data.count);
            loadImages(response.data.user.uid);
          }

          /*if (Array.isArray(response.data.user.images)) {
                        setImages(response.data.user.images);
                        setSrcImages(response.data.user.images[0]);
                    }*/
        })
        .catch((err) => console.error(err));
    };

    handleGetData();
  }, [refresh]);

  return (
    <div id="adminForm">
      {fullVisible && srcImages.length && (
        <FullScreenImage setVisible={setFullVisible} src={srcImages} />
      )}
      <AdminHeader />
      <main style={{ display: "flex" }}>
        <div style={{ height: "100%", width: "50%" }}>
          <form>
            <div style={{ marginBottom: "20px" }}>
              <span
                style={{
                  padding: "10px",
                  backgroundColor: "#00000030",
                  borderRadius: "100%",
                }}
              >
                {count}
              </span>
            </div>
            <label>
              Identificador público
              <input
                value={uid}
                onChange={(e) => setUid(e.target.value)}
                style={{
                  padding: "4px 0",
                  border: `4px solid #c4c4c4`,
                  textIndent: "12px",
                }}
              />
            </label>
            <label>
              CPF / CNPJ
              <input
                value={doc}
                onChange={(e) => handleDoc(e.target.value)}
                style={{
                  padding: "4px 0",
                  border: `4px solid ${validCpf ? "var(--hover)" : "tomato"}`,
                  textIndent: "12px",
                }}
              />
            </label>
            <button
              disabled={spinner}
              style={{
                backgroundColor: "tomato",
                marginTop: "8px",
                height: "50px",
                padding: "0 20px",
              }}
              onClick={(e) => handleAVSubmit(e, true)}
            >
              {spinner ? <Spinner visible={spinner} /> : "X"}
            </button>
            <button
              disabled={spinner}
              style={{
                backgroundColor: "green",
                marginTop: "8px",
                height: "50px",
                padding: "0 20px",
              }}
              onClick={(e) => handleAVSubmit(e)}
            >
              {spinner ? (
                <Spinner visible={spinner} />
              ) : (
                "verificar / próximo >"
              )}
            </button>
            <dl
              style={{
                marginTop: "40px",
                backgroundColor: "white",
                borderRadius: "12",
                padding: "10px 20px",
              }}
            >
              <dt style={{ marginBottom: "20px" }}>Imagens:</dt>
              {files.map((img: any, idx: number) => (
                <dd
                  onClick={() => {
                    setSrcImages(img);
                    setFullVisible(true);
                  }}
                  key={idx}
                  style={{
                    maxWidth: "300px",
                    maxHeight: "20px",
                    overflow: "hidden",
                    marginBottom: "20px",
                    backgroundColor: "#00ae7430",
                    padding: "10px",
                  }}
                >
                  <p style={{ margin: 0 }}>{img}</p>
                </dd>
              ))}
            </dl>
          </form>
        </div>
        <div style={{ height: "100%", width: "50%" }} className="center">
          {count && (
            <iframe
              title="servicess_profile"
              src={`${netlifyUrl}/profile/${uid}`}
              style={{
                width: "300px",
                height: "62vh",
                borderLeft: "8px solid black",
                borderRight: "8px solid black",
                borderTop: "50px solid black",
                borderBottom: "50px solid black",
                borderRadius: "40px",
              }}
            />
          )}
        </div>
      </main>
    </div>
  );
}

export default AdminVerified;
