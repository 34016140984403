import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

//Admin
import AdminHome from "./Admin/AdminComponents/AdminHome";
import AdminCounter from "./Admin/AdminComponents/AdminCounter";
import AdminCreateUser from "./Admin/AdminComponents/AdminCreateUser";
import AdminVerified from "./Admin/AdminComponents/AdminVerified";

//Docs:
import TermsOfUse from "./pages/docs/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./pages/docs/PrivacyPolicy/PrivacyPolicy";
//Pages:
import Home from "./pages/Home";
import Login from "./pages/Login";
import P404 from "./pages/P404";
import Search from "./pages/Search";
import Signin from "./pages/Signin";
import ResetPassword from "./pages/Validations/ResetPassword";
import Validation from "./pages/Validations/Validation";
import Details from "./pages/Details";
import Edit from "./pages/Work/Edit";
import Create from "./pages/Work/Create";
import Registration from "./pages/Registration";
import Categories from "./pages/Categories";

import ProfessionalProfile from "./pages/Perfil/ ProfessionalProfile";
import SelfProfile from "./pages/Perfil/ SelfProfile";
import Subscription from "./pages/Subscription";
import AdminMakePremium from "./Admin/AdminComponents/AdminMakePremium";
import AdminPartner from "./Admin/AdminComponents/AdminPartner";
import Central from "./pages/Central";
import ChatPage from "./pages/ChatPage";
import AdminExtendedCode from "./Admin/AdminComponents/AdminExtendedCode";
import VerifyProfile from "./pages/VerifyProfile";

function RoutesControl() {
    return (
        <Router>
            <Routes>
                <Route
                    path="*"
                    element={<P404 />}
                />
                <Route
                    path="/"
                    element={<Home />}
                />
                <Route
                    path="/login"
                    element={<Login />}
                />
                <Route
                    path="/search"
                    element={<Search />}
                />
                <Route
                    path="/signin"
                    element={<Signin />}
                />
                <Route
                    path="/details/:work_id"
                    element={<Details />}
                />
                <Route
                    path="/central"
                    element={<Central />}
                />
                <Route
                    path="/validation"
                    element={<Validation />}
                />
                <Route
                    path="/reset/password/:token"
                    element={<ResetPassword />}
                />
                <Route
                    path="/profile"
                    element={<SelfProfile />}
                />
                <Route
                    path="/profile/:uid"
                    element={<ProfessionalProfile />}
                />
                <Route
                    path="/work/create"
                    element={<Create />}
                />
                <Route
                    path="/work/edit/:work_id"
                    element={<Edit />}
                />
                <Route
                    path="/registration"
                    element={<Registration />}
                />
                <Route
                    path="/categories"
                    element={<Categories />}
                />
                <Route
                    path="/terms"
                    element={<TermsOfUse />}
                />
                <Route
                    path="/politica_de_privacidade"
                    element={<PrivacyPolicy />}
                />
                <Route
                    path="/verify/profile"
                    element={<VerifyProfile />}
                />
                <Route
                    path="/subscription"
                    element={<Subscription />}
                />
                <Route
                    path="/chat"
                    element={<ChatPage />}
                />

                {/* Admin */}
                <Route
                    path="/admin"
                    element={<AdminHome />}
                />
                <Route
                    path="/admin/countdown"
                    element={<AdminCounter />}
                />
                <Route
                    path="/admin/create"
                    element={<AdminCreateUser />}
                />
                <Route
                    path="/admin/verified"
                    element={<AdminVerified />}
                />
                <Route
                    path="/admin/make/premium"
                    element={<AdminMakePremium />}
                />
                <Route
                    path="/admin/partner"
                    element={<AdminPartner />}
                />
                <Route
                    path="/admin/generate/extended/code"
                    element={<AdminExtendedCode />}
                />
            </Routes>
        </Router>
    );
}

export default RoutesControl;