
function FullScreenImage({ src, setVisible }: { src: string, setVisible: any }) {
    return (
        <div
            style={{
                position: 'fixed',
                backgroundColor: '#00000030',
                width: '100vw',
                height: '100vh',
                zIndex: 4,
                top: 0,
                left: 0
            }}
            className="center"
        >
            <FullX setVisible={setVisible} />
            {/*<FullArrows />*/}

            <img
                src={src}
                style={{
                    maxHeight: '100vh',
                    maxWidth: '100vw'
                }}
            />
        </div>
    )
}


const FullX = ({ setVisible }: { setVisible: any }) => (
    <div
        style={{
            position: 'absolute',
            top: 20,
            right: 20,
            fontSize: '30px',
            color: 'white',
            textShadow: '0px 0px 10px #000',
        }}
        onClick={() => setVisible(false)}
    >
        <strong>{'X'}</strong>

    </div>
)

/*onst FullArrows = () => (
    <div style={{
        position: 'absolute',
        width: '94%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '40px',
        color: 'white',
        textShadow: '0px 0px 10px #000'
    }}>
        <strong>{'<'}</strong>
        <strong>{'>'}</strong>
    </div>
)
*/

export default FullScreenImage;