import { useState } from "react"
import api from "../../../config/connection/axios"

function AdminLogin({ refresh }: { refresh: (boo: boolean) => void }) {

  const [user, setUser] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const handleAdminLogin = (e: Event|any) => {

    e.preventDefault();

    api.put(
      '/admin/login',
      {
        user,
        password
      }
    ).then(r => {
      const result = r.data;
      window.sessionStorage.setItem('admin-serss-user', JSON.stringify(result));
      refresh(true);
    })
  }

  return (
    <form
      className="center column"
      style={{
        width: '100vw',
      }}
    >
      <label className='center column'>
        Usuário
        <input
          type="text"
          value={user}
          onChange={(e) => setUser(e.target.value)}
        />
      </label>

      <label className='center column'>
        Senha
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </label>
      <button onClick={handleAdminLogin}>confirmar</button>
    </form>
  )
}

export default AdminLogin