import { FaHandHoldingHeart } from "react-icons/fa"
import { IoLogoWhatsapp } from "react-icons/io5"
import FormButton from "../../Form/Inputs/layouts/FormButton"
import Icon from "../../Icon"
import { Link } from "react-router-dom"

const ContactUsCannot = ({ children }: { children: JSX.Element }) => (
    <>
        <div style={{ padding: '0 20px', border: '1px solid #c4c4c4', borderRadius: '10px', marginTop: '20px' }}>
            <div style={{ display: 'flex', color: '#222D36' }}>
                <Icon right={20}>
                    <FaHandHoldingHeart size={22} />
                </Icon>
                <p>Se não conseguir criar sua conta entre em contato com nossa
                    <strong> assistência </strong>

                    <Link to='/central' style={{ opacity: 0.6 }}>{'Central de atendimento'}</Link>

                </p>

            </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '48%' }}>

                <a href='https://api.whatsapp.com/send?phone=5541995928004&text=Olá,%20quero%20ajuda%20para%20criar%20minha%20conta!'>
                    <FormButton
                        style={{ backgroundColor: '#222D36', border: '1px solid #222D36' }}
                        text={<div className='center'>
                            <Icon right={8}>
                                <IoLogoWhatsapp size={18} />
                            </Icon>
                            Assistência
                        </div>}
                    />
                </a>

                {/*<a href='https://api.whatsapp.com/send?phone=5541995928004&text=Olá,%20quero%20ajuda%20para%20criar%20minha%20conta!'>
                    <FormButton
                        onClick={(e: any) => e.preventDefault()}
                        style={{ backgroundColor: '#222D36', border: '1px solid #222D36' }}
                        text={<div className='center'>
                            <Icon right={8}>
                                <IoLogoWhatsapp size={18} />
                            </Icon>
                            Assistência

                        </div>}
                    />
                </a>*/}
            </div>
            <div style={{ width: '48%' }}>
                {children}
            </div>
        </div>
    </>
)

export default ContactUsCannot