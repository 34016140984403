import "./style.css";

import SafeDiv from "../../../SafeDiv";
//import Card from "../../../Card";
import Perfil_ from "../../../../interfaces/perfil.interfaces";
import Avatar from "../../../Images/Avatar";
import { IoAddCircle, IoNewspaper } from "react-icons/io5";
import Icon from "../../../Icon";
import { Link } from "react-router-dom";
import GItems from "../../../GItems";
import Verify from "../../../Verify";
import { premiumDaysLeft } from "../../../../functions/premiumIsExpired";
import iosIsBoring from "../../../../functions/iosIsB";
import Bookmarkers from "../../../Bookmarkers";

export default function PerfilContainer({
    user, works,

    spinner,

    Self_EditProfileMenu,
    Self_EditProfileAvatar,
    Self_EditDeleteWork
}: Perfil_) {

    return (
        <SafeDiv style={{ backgroundColor: 'white' }}>
            <div id="PerfilContent" className="center" style={{ flexDirection: 'column' }}>
                <div id='perfil-wrapper' style={{ width: '70%' }}>
                    <div style={
                        Self_EditProfileAvatar
                            ? {}
                            : { border: '8px solid white', borderRadius: '36px', boxShadow: '-6px 6px 12px #00000024,0px 0px 2px #00000010', height:'fit-content' }
                    }>
                        <div className="perfil-avatar">
                            {Self_EditProfileAvatar
                                ? (
                                    <>
                                        {
                                            iosIsBoring.camera()
                                                ? Self_EditProfileAvatar
                                                : <Avatar borderRadius="30px" uid={user.uid} size={60} />
                                        }
                                    </>
                                ) : (
                                    <Avatar borderRadius="30px" uid={user.uid} size={60} />
                                )
                            }
                        </div>
                    </div>
                    <div className="p-texts-wrapper">
                        <div>
                            <div>
                                <h6 className='profession'>{user.profession}</h6>

                                {
                                    user.name
                                        ? <div
                                            style={{
                                                display: 'flex',
                                                position: 'relative',
                                                justifyContent: window.innerWidth < 960 ? 'center' : 'flex-start'
                                            }}>
                                            <h2 className='name'>{user.name}</h2>
                                            {
                                                user.verified &&
                                                <Verify
                                                    haveAvatar={user.avatar}
                                                    inline
                                                    uid={user.uid}
                                                />
                                            }
                                        </div>
                                        : <h2 className='no-data'>{''}</h2>
                                }
                            </div>
                            <p className='descricao'>{user.description}</p>
                        </div>
                        <div style={{ width: '100%' }} className='center'>
                            {
                                Self_EditProfileMenu
                                    ? Self_EditProfileMenu
                                    : <Bookmarkers
                                        profile={true}

                                        partner={user.partner}
                                        premium={user.premium}

                                        remote={false}
                                        verified={false}

                                        user_name={user.name}
                                    />
                            }
                        </div>
                    </div>
                </div>
                {
                    !spinner && <Desc
                        user={user}
                        works={works}
                        Self_EditDeleteWork={Self_EditDeleteWork}
                        Self_EditProfileMenu={Self_EditProfileMenu}
                    />
                }
            </div>
        </SafeDiv>
    );
}

const Desc = ({ works, user, Self_EditDeleteWork, Self_EditProfileMenu }: { works: Perfil_['works'], user: Perfil_['user'], Self_EditDeleteWork: any, Self_EditProfileMenu: any }) => {
    return (
        <div id='Desc' className="center column">
            <div className='desc-top center column'>
                <h2 className='desc-name center'>
                    <Icon style={{ paddingRight: '12px' }}>
                        <IoNewspaper />
                    </Icon>
                    Meus Anúncios
                </h2>
            </div>
            {
                (
                    !works.length &&
                    (JSON.parse(window.localStorage.getItem('serss-user') || '{}').uid) === user.uid
                ) &&
                <div className='center hover-color' style={{ marginBottom: '60px' }}>
                    <Link
                        to='/work/create'
                        className="center column"
                    >
                        <span className="center">
                            <IoAddCircle size={100} />
                        </span>
                        <h4>Anunciar meu Serviço</h4>
                    </Link>
                </div>
            }
            {
                works.map((work: any, idx) => (
                    <div key={idx}>
                        {
                            (
                                !work.suspended ||
                                (JSON.parse(window.localStorage.getItem('serss-user') || '{}').uid) === user.uid
                            )
                            &&

                            <div style={{ marginBottom: '48px' }}>
                                <GItems
                                    premium={premiumDaysLeft(work.premium)}
                                    type='profile'
                                    wid={work.id}
                                    key={idx}
                                    title={work.title}
                                    self={Self_EditProfileMenu && true}
                                />
                            </div>

                        }
                    </div>
                ))
            }
        </div>
    )
}

/*
const SuspendedAlert = ({ id }: any) => (
    <Link to={`/details/${id}`}>
        <div
            className="center"
            style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0, left: 0,
                backgroundColor: '#00000040',
                zIndex: 2,
                borderRadius: '10px'
            }}
        >
            <div
                className="center column"
                style={{
                    backgroundColor: 'white',
                    width: '70%',
                    height: '60%',
                    borderRadius: '14px',
                    textAlign: 'center',
                    padding: '30px'
                }}
            >

                <Icon style={{ margin: '-10px 0 10px 0' }}>
                    <IoAlertCircle color='#c4c4c4' size={40} />
                </Icon>
                <strong>
                    Anúncio temporariamente desativado
                </strong>
                <p>Estamos a disposição para esclarecimentos e pricipalmente para te ajudar.</p>
                <div className="center">

                </div>
            </div>
        </div>
    </Link>
)
*/