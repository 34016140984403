import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Footer from "../../components/Footer"
import Header from "../../components/Headers/Header"
import NavigationBar from "../../components/mobile/NavigationBar"
import PerfilContainer from "../../components/rlx-components/Containers/PerfilContainer"
import api from "../../config/connection/axios"
import P404 from "../P404"


function ProfessionalProfile() {
    window.scrollTo(0, 0);

    const { uid } = useParams()

    const [works, setWorks] = useState<Array<Object>>([])
    const [user, setUser] = useState<object>({})
    const [spinner, setSpinner] = useState<boolean>(true)

    useEffect(() => {
        fetchWorksData()
    }, [])

    const fetchWorksData = async () => {
        if (uid) {
            await api.get(`/cards/belongs/${uid}`)
                .then(r => {
                    const result = r.data;
                    setWorks(result || [])
                })

            await api.get(`/users/load/${uid}`)
                .then(r => {
                    const result = r.data;
                    setUser(result || [])
                    setSpinner(false)
                })
                .catch(() => setSpinner(false))
        }
    }

    return (
        <>
            {
                uid !== 'admin' ?
                    <>
                        <Header />
                        <PerfilContainer
                            user={user}
                            works={works}
                            spinner={spinner}
                        />
                        <Footer />
                        <NavigationBar />
                    </>
                    : <P404 />
            }
        </>
    )
}

export default ProfessionalProfile;