import './styles.css'
import {
    IoCloseOutline,
    IoAddCircleOutline,
    IoLocationOutline,
    IoListOutline,
    IoChevronBack,
    IoCreateOutline,
    IoNewspaperOutline

} from 'react-icons/io5'
import { Link, useLocation } from 'react-router-dom';
//import AvatarPlaceholder from '../Images/AvatarPlaceholder';
import { useState } from 'react';
import Avatar from '../Images/Avatar';
//import chatUrl from '../../constants/chatClient'
import Icon from '../Icon';
import ShLi from './layouts/ShLi';
import CatList from './layouts/CatList';
//import socket from '../../config/connection/socket';

interface IsideBar {
    setSideBarIsVisible: any,
    setLocationIsVisible: any,
    type?: string,

    userName?: string,
    userUid?: string,
    userProfession?: string,

    setChat: any
}

function SideBar({
    setSideBarIsVisible,
    setLocationIsVisible,
    type = 'default',

    userProfession,
    userName,
    userUid,

    setChat
}: IsideBar) {

    const [switcher, setSwitcher] = useState<string>(type);
    const location = useLocation();

    const makeLocVisible = () => {
        setSideBarIsVisible(false)
        setLocationIsVisible(true)
    }

    return (
        <div className='SideBar-Container' >
            <div id="SideBar" style={{ backgroundColor: switcher === 'default' ? '#fff' : '#ededed' }}>
                <button className='SB-close-button' onClick={() => setSideBarIsVisible(false)}>
                    <Icon>
                        <IoCloseOutline />
                    </Icon>
                </button>
                {switcher === 'default' &&
                    <div>
                        <ul className='SB-list'>
                            {
                                (location.pathname !== '/profile')
                                    ? <li className='SB-list-li-user'>
                                        <div className='SB-list-li-user-container'>
                                            <div className='SB-list-li-user-info'>
                                                <div style={{ position: 'relative', width: '60px', height: '60px' }}>
                                                    <Avatar
                                                        uid={userUid}
                                                        size={20}
                                                    />
                                                </div>
                                                <span className='SB-list-li-user-text'>
                                                    {
                                                        userName
                                                            ? (
                                                                <>
                                                                    <h5>{userProfession}</h5>
                                                                    {userName}
                                                                </>

                                                            )
                                                            : (
                                                                <>
                                                                    Faça login
                                                                    <br />
                                                                    ou cadastre-se
                                                                </>
                                                            )
                                                    }
                                                </span>
                                            </div>
                                            <div className='SB-list-li-user-buttons'>
                                                {
                                                    userName
                                                        ? (

                                                            <Link
                                                                to='/profile'
                                                                className='SB-list-li-user-btn-login SB-btn'
                                                                style={{ width: '74%' }}
                                                            >
                                                                Ver perfil
                                                            </Link>

                                                        )
                                                        : (
                                                            <>
                                                                <Link
                                                                    to='/login'
                                                                    className='SB-list-li-user-btn-login SB-btn'

                                                                >
                                                                    Entrar
                                                                </Link>
                                                                <Link
                                                                    to='/signin'
                                                                    className='SB-list-li-user-btn-signin SB-btn'

                                                                >
                                                                    Cadastrar
                                                                </Link>
                                                            </>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    </li>
                                    : <div style={{ height: '40px', width: '100%', backgroundColor: '#eee' }} />
                            }
                            <div style={{ overflowY: 'auto', height: 'calc(100vh - 210px)' }}>
                                {userUid === 'admin' && <Link to='/admin' style={{ color: 'tomato' }}><ShLi text='Admin'>@</ShLi></Link>}
                                <Link to={userUid ? '/work/create' : '/login'}>
                                    <ShLi text='Anunciar meu serviço'>
                                        <IoAddCircleOutline />
                                    </ShLi>
                                </Link>
                                {
                                    (location.pathname !== '/profile') &&
                                    <Link to={userUid ? '/profile' : '/login'}>
                                        <ShLi text='Editar meus anúncios'>
                                            <IoNewspaperOutline />
                                        </ShLi>
                                    </Link>
                                }
                                <Link to={userUid ? '/registration' : '/login'}>
                                    <ShLi text='Dados pessoais'>
                                        <IoCreateOutline />
                                    </ShLi>
                                </Link>
                                <ShLi text='Localização' onClick={makeLocVisible}>
                                    <IoLocationOutline />
                                </ShLi>
                                <ShLi text='Categorias' onClick={() => setSwitcher('categories')}>
                                    <IoListOutline />
                                </ShLi>
                                {/*<Link to='/login'>
                                    <ShLi text='Chat' onClick={(e: Event) => {
                                        if (userUid) {
                                            e.preventDefault();
                                            setChat(true);
                                            setSideBarIsVisible(false);
                                        }
                                    }}>
                                        <Bubble uid={userUid} />
                                    </ShLi>
                                </Link>*/}
                            </div>
                        </ul>
                    </div>
                }
                {switcher === 'categories' &&
                    <div>
                        <ul className='SB-list'>
                            <li style={{ padding: '20px 0 20px 16px' }}>
                                <div className='SB-list-li-user-container'>
                                    <div
                                        className='SB-list-li-user-info SB-list-li-user-info-hover '
                                        onClick={() => setSwitcher('default')}
                                    >
                                        <span className='center'>
                                            <IoChevronBack />
                                        </span>
                                        <span className='SB-list-li-user-text'>Principais Categorias</span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <CatList type='sidebar' />
                            </li>
                        </ul>
                    </div>
                }
            </div>
            <div className='SB-fill-rest' onClick={() => setSideBarIsVisible(false)} />
        </div >
    )
}
export default SideBar