import firebase from 'firebase/compat/app';
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBJnblv0JIkFsPJ0rOovnlA-fgILjpm_wc",
  authDomain: "temp-3d85a.firebaseapp.com",
  projectId: "temp-3d85a",
  storageBucket: "temp-3d85a.appspot.com",
  messagingSenderId: "517079609571",
  appId: "1:517079609571:web:759b265beabe0f40f615d6",
  measurementId: "",
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();

export { db, storage, auth };